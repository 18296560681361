
    import { Component, Vue, Watch } from 'vue-property-decorator'
    @Component({
        name: 'PrivacyPolicy'
    })
    export default class PrivacyPolicy extends Vue {
        private ppList: Array<any> = [
            { fw: true, text: '更新日期：2020年12月15日' },
            { fw: true, text: '生效日期：2020年12月15日' },
            { fw: false, text: '尊敬的勤学用户：' },
            { fw: false, text: '勤学深知个人信息保护的重要性，因此，在您使用勤学的过程中所收集到的个人信息将只用于本《隐私权政策》中所规定的用途。您在使用我们的产品或服务前请仔细阅读并确认您已经充分理解本隐私政策所写明的内容，并自愿接受所有的服务条款。如您是未成年用户的法定监护人，请您仔细阅读并选择是否同意本隐私政策，同时请您积极引导未成年人增强个人信息保护的意识；如您是未成年用户，请在征得您法定监护人同意的前提下使用我们的产品、服务或向我们提供信息。我们将尽最大努力保护您的隐私。 本隐私权政策具体包括以下内容：' },
            { fw: true, text: '一、如何收集和使用您的个人信息' },
            { fw: true, text: '二、嵌入的第三方代码、插件收集使用个人信息' },
            { fw: true, text: '三、您如何访问或修改您的个人信息' },
            { fw: true, text: '四、共享、转让或公开披露收集信息的具体情形' },
            { fw: true, text: '五、信息保存及跨境传输' },
            { fw: true, text: '六、权限管理及注销等功能描述' },
            { fw: true, text: '七、我们如何使用Cookie及相关技术' },
            { fw: true, text: '八、未成年人的信息保护' },
            { fw: true, text: '九、本隐私权政策的变更' },
            { fw: true, text: '十、如何联系我们或反馈意见' },
            { fw: false, text: '勤学提醒您，如您访问本产品或注册用户，便视为接受了以下隐私政策，请您仔细阅读以下内容，尤其是加粗字体。' },
            { fw: true, text: '一、如何收集或使用您的个人信息' },
            { fw: false, text: '您理解并同意：' },
            { fw: false, text: '为给您带来更好的产品和服务体验，我们可能会不时推出新的或优化后的功能，可能需要收集、使用新的个人信息或变更个人信息使用目的或方式。对此，我们将通过更新本政策、弹窗、页面提示等方式另行向您说明对应信息的收集目的、范围及使用方式，且在征得您明示同意后进行收集、使用。在此过程中，如果您有任何疑问、意见或建议，可通过本隐私权政策第十条提供的联系方式与我们联系，我们会尽快为您作出解答。' },
            { fw: true, text: '1．成为勤学注册用户并登陆' },
            { fw: false, text: '如果您在我们的勤学官网或者勤学APP应用上成为我们的注册用户并登陆使用勤学，您需要创建用户名并设置密码，同时您需要提供姓名、性别、地区、就读年级以及与身份信息对应的可供通信联络的手机号码等信息，以便我们确认您的身份以及您所参加的教学活动，并在有特殊事项时通过您的手机号码与您取得联系。此外，在课堂讲义邮寄的功能中可能会需要您提供收件信息，以便在您无法亲自前往勤学校区领取课堂讲义时采取邮寄形式。在勤学官网或者勤学APP应用上注册的账号是可以共同使用的。' },
            { fw: true, text: '2．关注使用公众号、小程序' },
            { fw: false, text: '当您关注、使用我们的微信公众号时，我们会收集您的微信昵称、头像信息，用于您在使用我们的平台时确认您的身份信息；' },
            { fw: false, text: '当您使用该微信小程序时，我们可能会收集您的微信UnionID、OpenID、UserID、微信昵称、微信头像、登录记录信息，用于您在使用我们的平台时确认您的身份信息。' },
            { fw: false, text: '当您使用微信公众号或微信小程序的特定功能或服务时，您可能需要注册、登录勤学账号或使用微信账号快速登录，当您选择后者时，我们可能会收集您的手机号码，对于此类信息，我们将按照微信公众号和微信小程序的个人信息收集规则，根据相应提示获取您的同意。' },
            { fw: false, text: '当您参加线上课程等活动时，我们将收集您主动填写的个人信息，且仅用于您所参加的教学活动的身份识别、教材发送及统计工作。' },
            { fw: true, text: '3．参加活动及交流、互动' },
            { fw: false, text: '当您参加我们的用户营销活动时，我们需要您提供某些信息，可能会包含您的姓名、照片、通讯地址、联系方式。这些信息可以帮助我们对活动结果进行公示，对活动进行后续宣传，与您取得联系，向您发放礼品或提供服务等。如果您拒绝提供此类信息，可能导致无法参与相应活动、无法收到礼品，但不会影响您使用勤学提供的核心业务功能服务。您在进行课程交流、互动及评价过程中，也可能会包含或者关联到您的个人信息，请谨慎考虑披露相关个人敏感信息。' },
            { fw: false, text: '您在进行课程交流、互动及评价过程中，也可能会包含或者关联到您的个人信息（可能会包含您的手机号、姓名、学校、年级、地区等），且仅用于您所参加的教学活动的身份识别、教材发送及统计工作，请谨慎考虑披露相关个人敏感信息。' },
            { fw: true, text: '4．问卷调研' },
            { fw: false, text: '为了向您提供更好的服务和网络使用环境，我们可能会发起问卷调研以了解您的使用偏好、相关意见，例如您感兴趣的资料、您感兴趣的课程等。在您参加问卷调研/使用勤学问卷调研的功能时，我们需要您提供某些信息，可能会包含您的手机号、年龄、地区、在读校区等。这些信息将帮助我们进行数据统计，并更好地针对您的个人情况为您提供更符合、优质的服务。您可以自主选择是否参加问卷调研，调研结果将仅供统计使用。' },
            { fw: true, text: '5．日志信息' },
            { fw: false, text: '（1）为开展数据分析和更好地改善我们的服务，我们可能会收集您的日志信息，例如您的硬件设备型号、屏幕宽度和高度、您所使用的软件版本信息、您的操作系统及版本、您的IP地址、您所在的位置、移动网络信息、您的设备ID、设备制造商、设备型号、运营商、IMEI/ANDROIDID/IDFA/OPENUDID/GUID、SIM卡IMSI信息。' },
            { fw: false, text: '（2）您提供的上述信息及上述被获取的信息，将在您使用本服务期间持续授权我们使用。除非法律对存储时间有特殊要求，在您注销账号时，我们将停止使用并匿名化上述信息。' },
            { fw: true, text: '6．除上述信息，勤学的应用上可能需要下面列出的权限来支持相关功能的使用' },
            { fw: true, text: 'Android平台：' },
            { fw: false, text: '(1)大致/精准的（GPS）位置权限：在使用附近校区的功能中，用于定位当前位置以便告知您附近有哪些勤学的校区，以及计算当前位置和目的校区位置距离的功能，您有权拒绝授权许可，拒绝仅会使您无法使用上述功能，但不影响您正常使用勤学的其他功能；' },
            { fw: false, text: '(2)相机拍照权限：在使用服务模块中扫描作业二维码、个人中心修改自己的头像、上传学生的作业、在线拍照并发送相关图片文件、直播课程上台发言等功能中，需要用到这个权限，您有权拒绝授权许可，拒绝仅会使您无法使用上述功能，但不影响您正常使用勤学的其他功能；' },
            { fw: false, text: '(3)读取电话状态(IMSI,IMEI)权限：在使用APP时，为了可以了解没有登录的用户的使用情况，以便改善APP体验，获取该权限是为了生成一个设备唯一ID和没登录的用户进行关联，您有权拒绝授权许可，拒绝仅会使您无法使用上述功能，但不影响您正常使用勤学的其他功能；' },
            { fw: false, text: '(4)读取/写入外置存储器：在使用个人中心修改用户头像并从相册选择图片上传时、从相册选择图片上传天天练作业时、联系在线客服并从相册选择图片发送时，需要用到这个权限，您有权拒绝授权许可，拒绝仅会使您无法使用上述功能，但不影响您正常使用勤学的其他功能；' },
            { fw: false, text: '(5)获取使用及搜索蓝牙权限：在使用勤学老师端考勤的功能时，需要老师到校区使用手机的蓝牙搜索校区的蓝牙进行考勤，您有权拒绝授权许可，拒绝仅会使您无法使用上述功能，但不影响您正常使用勤学的其他功能；' },
            { fw: false, text: '(6)录音权限：在使用课堂直播时，老师和学生使用发言功能时，在客服功能需要发送语音消息时，以及在老师批阅学生作业发送批阅语音时，都需要使用手机设备的话筒，所以需要获取录音的权限，您有权拒绝授权许可，拒绝仅会使您无法使用上述功能，但不影响您正常使用勤学的其他功能；' },
            { fw: false, text: '(7)获取悬浮窗权限：在老师使用老师端考勤打卡的时候，会有一条消息置顶，方便老师操作相关打卡功能，这条消息是用系统悬浮窗的功能实现，您有权拒绝授权许可，拒绝仅会使您无法使用上述功能，但不影响您正常使用勤学的其他功能；' },
            { fw: true, text: 'iOS平台：' },
            { fw: false, text: '(1)位置权限：在使用附近校区的功能中，用于定位当前位置以便告知您附近有哪些勤学的校区，以及计算当前位置和目的校区位置距离的功能，您有权拒绝授权许可，拒绝仅会使您无法使用上述功能，但不影响您正常使用勤学的其他功能；' },
            { fw: false, text: '(2)录音权限：在使用课堂直播时，老师和学生使用发言功能时，在客服功能需要发送语音消息时，以及在老师批阅学生作业发送批阅语音时，都需要使用手机设备的话筒，所以需要获取录音的权限，您有权拒绝授权许可，拒绝仅会使您无法使用上述功能，但不影响您正常使用勤学的其他功能；' },
            { fw: false, text: '(3)访问相机权限：在使用服务模块中扫描作业二维码、个人中心修改自己的头像、上传学生的作业、在线拍照并发送相关图片文件、直播课程上台发言等功能中，需要用到这个权限，您有权拒绝授权许可，拒绝仅会使您无法使用上述功能，但不影响您正常使用勤学的其他功能；' },
            { fw: false, text: '(4)访问照片权限：在使用个人中心修改用户头像并从相册选择图片上传时、从相册选择图片上传今日天天练时、联系在线客服并从相册选择图片发送时，需要用到这个权限，您有权拒绝授权许可，拒绝仅会使您无法使用上述功能，但不影响您正常使用勤学的其他功能；' },
            { fw: false, text: '(5)获取使用及搜索蓝牙权限：在使用勤学老师端考勤的功能时，需要老师到校区使用手机的蓝牙搜索校区的蓝牙进行考勤，您有权拒绝授权许可，拒绝仅会使您无法使用上述功能，但不影响您正常使用勤学的其他功能；' },
            { fw: true, text: '微信小程序平台：' },
            { fw: false, text: '(1)位置权限：在使用附近校区的功能中，用于定位当前位置以便告知您附近有哪些勤学的校区，以及计算当前位置和目的校区位置距离的功能，您有权拒绝授权许可，拒绝仅会使您无法使用上述功能，但不影响您正常使用勤学的其他功能；' },
            { fw: false, text: '(2)录音权限：在使用老师端批阅学生作业发送批阅语音时，需要用到手机设备的话筒，所以需要录音的权限，您有权拒绝授权许可，拒绝仅会使您无法使用上述功能，但不影响您正常使用勤学的其他功能；' },
            { fw: false, text: '(3)相机拍照权限：在使用个人中心修改自己的头像、上传学生的作业等功能中，需要用到这个权限，您有权拒绝授权许可，拒绝仅会使您无法使用上述功能，但不影响您正常使用勤学的其他功能；' },
            { fw: false, text: '(4)读取/写入外置存储器：在使用个人中心修改用户头像并从相册选择图片上传时、从相册选择图片上传今日天天练时、联系在线客服并从相册选择图片发送时，需要用到这个权限，您有权拒绝授权许可，拒绝仅会使您无法使用上述功能，但不影响您正常使用勤学的其他功能；' },
            { fw: false, text: '(5)获取个人信息权限：在使用小程序登录或注册的功能时，为方便用户快捷登录或注册，勤学小程序会从微信中获取个人信息，该权限会获取昵称、头像、地区、性别等信息，获取的信息仅用于确认用户的身份信息，以便提供相应的服务，您有权拒绝授权许可，拒绝仅会使您无法使用上述功能，但不影响您正常使用勤学的其他功能；' },
            { fw: true, text: '7．我们可能将在向您提供服务的过程之中所收集的信息用作下列用途' },
            { fw: false, text: '(1)向您提供服务。在我们提供服务时，用于身份验证、客户服务、安全防范、诈骗监测、存档和备份用途，确保我们向您提供的产品和服务的安全性；' },
            { fw: false, text: '(2)使我们更加了解您如何接入和使用我们的服务，从而针对性地回应您的个性化需求，例如位置设定、个性化的帮助服务和指示，或对您和其他用户作出其他方面的回应，帮助改善我们现有服务；' },
            { fw: false, text: '(3)软件认证或管理软件升级；' },
            { fw: false, text: '(4)让您参与有关我们产品和服务的调查。' },
            { fw: true, text: '8．为了让您有更好的体验、改善我们的服务或您同意的其他用途，在符合相关法律法规的前提下，我们也会告知您并在征得您同意的情况下收集您的某些信息，以汇集信息或者个性化的方式，用于我们的其他服务。例如，在您使用我们的一项服务时所收集的信息，可能在另一服务中用于向您提供特定内容，或向您展示与您相关的信息。如果我们在相关服务中提供了相应选项，您也可以授权我们将该服务所提供和储存的信息用于我们的其他服务' },
            { fw: true, text: '二、嵌入的第三方代码、插件收集使用个人信息' },
            { fw: false, text: '为履行服务之必须，获得您的同意后，我们嵌入的第三方代码、插件将基于如下目的、方式、范围收集使用个人信息。如果您希望关闭下列权限，您可以在手机系统的“应用权限设置”中进行关闭。关闭权限仅使您无法使用或全面使用对应服务，但不影响您正常使用勤学的其他功能。' },
            { fw: true, text: '1.百度地图、定位SDK' },
            { fw: false, text: '在您使用附近校区功能的时候需要进行定位功能，查看校区详情的时候需要再地图上展示相应位置，我们会使用第三方提供的百度定位SDK及百度地图SDK。它们会获取您的定位权限以及设备唯一识别码（IMEI/android ID/IDFA/OPENUDID/GUID），用于帮助您定位自己当前的位置，在地图中做合适的展现。' },
            { fw: false, text: '百度开放平台：http://lbsyun.baidu.com/' },
            { fw: true, text: '2.友盟统计、推送SDK' },
            { fw: false, text: '为了实现客户端的数据统计以及客服的接收消息功能，我们会使用第三方提供的友盟统计/推送SDK。友盟统计SDK会获取您的应用安装列表、定位权限、社交账号信息、设备MAC地址、设备唯一识别码（IMEI/android ID/IDFA/OPENUDID/GUID）、SIM卡IMSI信息，用于做客户端功能使用的统计以及为您提供应用的推送功能。' },
            { fw: false, text: '友盟开发者中心：https://developer.umeng.com/docs' },
            { fw: true, text: '3.腾讯Buggly SDK' },
            { fw: false, text: '为了更好的监控客户端的异常、错误和崩溃情况，我们会使用第三方提供的腾讯Buggly SDK。腾讯Buggly SDK会获取您的设备唯一识别码（IMEI/android ID/IDFA/OPENUDID/GUID）、日志信息，用于对客户端异常、错误和崩溃的监控和分析。' },
            { fw: false, text: '腾讯Buggly开发者中心：https://bugly.qq.com/docs/' },
            { fw: true, text: '4.声网SDK' },
            { fw: false, text: '为了有更好的线上直播体验，我们会使用第三方提供的声网SDK。声网SDK会获取您的电话状态、录音、摄像头、读取外部存储器、设备唯一识别码（IMEI/android ID/IDFA/OPENUDID/GUID）等权限信息。' },
            { fw: false, text: '声网开发者中心：https://docs.agora.io/cn/documents' },
            { fw: true, text: '三、您如何访问修改及保护您的个人信息' },
            { fw: false, text: '1.我们将尽量采取适当的技术手段，保证您可以访问、更新和更正自己的注册信息或使用我们的服务时提供的其他个人信息。在访问、更新、更正和删除前述信息时，我们可能会要求您进行身份验证，以保障账户安全。' },
            { fw: false, text: '2.在修改用户信息的功能中，您也可以自行选择是否完善QQ号、微信号、家庭住址、在读学校等信息。' },
            { fw: false, text: '3.我们非常重视信息安全。我们努力为用户的信息安全提供保障，防止数据遭到未经授权访问、公开披露、使用、修改、损坏或丢失。' },
            { fw: false, text: '4.我们将在合理的安全水平内使用各种安全保护措施以保障信息的安全。例如，我们会使用加密技术（如SHA1、MD5、SSL）、匿名化处理等手段来确保您的个人敏感数据的保密性。' },
            { fw: false, text: '5.我们建立专门的管理制度、流程和组织以保障信息的安全。例如，我们会部署访问控制机制，确保只有授权人员才可访问个人信息；以及我们会举办安全和隐私保护培训课程，加强员工对于保护个人信息重要性的认识。' },
            { fw: false, text: '6.我们也请您理解，在互联网行业由于技术的限制和飞速发展以及可能存在的各种恶意攻击手段，即便我们竭尽所能加强安全措施，也不可能始终保证信息的百分之百安全。请您了解，您使用我们的产品和/或服务时所用的系统和通讯网络，有可能在我们控制之外的其他环节而出现安全问题。' },
            { fw: false, text: '7.在不幸发生个人信息安全事件后，我们将按照法律法规的要求，及时向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。我们将及时将事件相关情况以邮件、信函、电话、推送通知等方式告知您，难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。 同时，我们还将按照监管部门要求，主动上报个人信息安全事件的处置情况。' },
            { fw: true, text: '四、共享、转让或公开披露收集信息的具体情形' },
            { fw: true, text: '1.共享' },
            { fw: false, text: '根据您的选择，勤学可能会向第三方共享您的某种个人信息，如订单信息、账户信息、联系方式或位置信息等，以保障为您提供的产品或服务顺利完成。但勤学仅会出于合法、正当、必要、特定、明确的目的共享您的个人信息，并且只会共享提供产品或服务所必要的个人信息。若涉及到未成年人个人信息的，勤学仅会在获得法定监护人的同意情况下，想其他第三方共享该信息。' },
            { fw: true, text: '2.转让' },
            { fw: false, text: '我们不会将您的个人信息转让给任何公司、组织和个人，但以下情况除外：' },
            { fw: false, text: '(1)事先获得您的明确同意或授权；' },
            { fw: false, text: '(2)根据适用的法律法规、法律程序的要求、强制性的行政或司法要求；' },
            { fw: false, text: '(3)在涉及合并、收购、资产转让或类似的交易时，如涉及到个人信息转让，我们会要求新的持有您个人信息的公司、组织继续受本隐私政策的约束，否则,我们将要求该公司、组织重新向您征求授权同意。' },
            { fw: false, text: '(4)涉及未成年人个人信息的转让，需事先经过其法定监护人的同意。' },
            { fw: true, text: '3.公开披露' },
            { fw: false, text: '我们不会将您的个人信息公开披露给任何第三方，但以下情况除外：' },
            { fw: false, text: '(1)事先获得您的明确同意或主动选择；' },
            { fw: false, text: '(2)根据适用的法律法规、法律程序的要求、强制性的行政或司法要求，必须提供您的个人信息的情况下，勤学可能会依法披露您的个人信息，但会要求对方出具规范性政府文件或司法文件；' },
            { fw: false, text: '(3)在涉及合并、收购、资产转让或类似的交易时，如涉及到个人信息转让，我们会要求新的持有您个人信息的公司、组织继续受本隐私政策的约束，否则,我们将要求该公司、组织重新向您征求授权同意。' },
            { fw: false, text: '(4)一般情况下，勤学禁止披露未成年人的个人信息，但基于遵守相关法律规定目的的除外。' },
            { fw: true, text: '五、信息保存及跨境传输' },
            { fw: false, text: '勤学收集到的您的个人信息，将会采取妥善措施加以保护。如您从中国大陆以外地区访问勤学，您的个人信息将会在中国大陆得以保存，勤学将会按照本隐私权政策为您的个人信息提供保护。' },
            { fw: true, text: '六、权限管理、撤销授权及注销等功能描述' },
            { fw: true, text: '1.权限管理及撤销' },
            { fw: false, text: '为履行服务之必须，我们将获得您的同意后，开启对应手机功能权限。您有权拒绝权限开启，拒绝仅使您无法使用或全面使用该服务，但不影响您正常使用勤学的其他功能。' },
            { fw: false, text: '您可以在【我的】-【设置】-【隐私权限设置】中，查看或关闭给予勤学的授权，或者是打开手机系统中的“应用权限设置功能”查看。您也可以在手机系统的“应用权限设置”中进行查看或关闭。关闭权限仅使您无法使用或全面使用对应服务，但不影响您正常使用勤学的其他功能。' },
            { fw: true, text: '2.注销等功能描述' },
            { fw: false, text: '(1)除以下情形外，您可以自主删除个人信息或注销账户：' },
            { fw: false, text: '为配合人民检察院、公安机关、国家安全机关侦查用户使用勤学各应用过程中产生的犯罪行为，更好保护其他用户生命财产安全，为配合人民法院查清案情，您的个人信息和账户将被保存。' },
            { fw: false, text: '为遵守法律法规、政策等关于个人信息的保存期限的规定。' },
            { fw: false, text: '(2)您可通过联系客服的方式随时注销此前注册的账户，我们将尽快删除该账号。账户注销可能会导致清除您的所有用户数据和账户信息且不可恢复，造成我们无法为您提供服务；但在特定情形下，如合理必要地履行我们的法律义务、解决争议、防止欺诈与滥用，我们将在使用者账号注销后保留不可识别个人的信息。' },
            { fw: false, text: '(3)访问您的个人信息，您有权访问您的个人信息，法律法规规定的例外情况除外。您可以通过以下方式自行访问您的个人信息：' },
            { fw: false, text: '常用信息——您可以登录app后通过“我-点击头像” 的栏目，查看编辑或删除个人常用信息。' },
            { fw: false, text: '订单信息——您可以登录app后通过“服务->我的订单”的栏目，查看或删除订单信息。' },
            { fw: false, text: '(4)删除您的个人信息，在以下情形中，您可以向我们提出删除个人信息的请求： ' },
            { fw: false, text: '如果我们处理个人信息的行为违反法律法规； ' },
            { fw: false, text: '如果我们收集、使用您的个人信息，却未征得您的同意； ' },
            { fw: false, text: '如果我们处理个人信息的行为违反了与您的约定； ' },
            { fw: false, text: '如果您不再使用我们的产品或服务，或您注销了账号；' },
            { fw: false, text: '如果我们不再为您提供产品或服务。 ' },
            { fw: false, text: '若我们决定响应您的删除请求，我们还将同时通知从我们获得您的个人信息的实体，要求其及时删除，除非法律法规另有规定，或这些实体获得您的独立授权。' },
            { fw: false, text: '当您从我们的服务中删除信息后，我们可能不会立即从备份系统中删除相应的信息，但会在备份更新时删除这些信息。 ' },
            { fw: false, text: '(5) 约束信息系统自动决策 ' },
            { fw: false, text: '在某些业务功能中，我们可能仅依据信息系统、算法等在内的非人工自动决策机制做出决定。如果这些决定显著影响您的合法权益，您有权要求我们做出解释，我们也将提供适当的救济方式。 ' },
            { fw: false, text: '(6)响应您的上述请求 ' },
            { fw: false, text: '如果您通过前述条款所述路径操作对您的权限管理或注销等功能操作存在任何问题，您可以通过第本政策十条联系方式与我们取得联系。' },
            { fw: false, text: '为保障安全，您可能需要提供书面请求，或以其他方式证明您的身份。我们可能会先要求您验证自己的身份，然后再处理您的请求。 我们将在十五天内做出答复。' },
            { fw: true, text: '七、我们如何使用Cookie及相关技术' },
            { fw: false, text: '为使您获得更好的使用体验，当您使用勤学或网站的服务时，我们可能会通过小型数据文件识别您的身份，这么做可以帮您省去重复输入注册信息的步骤，或者帮助判断您的账户安全状态。这些数据文件可能是Cookie, Flash Cookie, 您的浏览器或关联应用程序提供的其他本地存储（以下简称Cookie）。请您理解，我们的某些服务只能通过使用 Cookie 才可得到实现。我们不会将Cookie用于本政策所述目的以外的任何用途。如您的浏览器或浏览器附加服务允许，您可以修改对 Cookie 的接受程度或者拒绝勤学网站的Cookie。多数浏览器工具中的“帮助”部分会告诉您怎样防止您的浏览器接受新的 Cookie，怎样让您的浏览器在您收到一条新Cookie 时通知您或者怎样彻底关闭Cookie。此外，您可以通过改变浏览器附加程序的设置，或通过访问提供商的网页，来关闭或删除浏览器附加程序使用的类似数据。但这一举动在某些情况下可能会影响您使用勤学提供的服务。' },
            { fw: true, text: '八、未成年人的信息保护' },
            { fw: false, text: '1.我们极其重视对未成年人个人信息的保护。如果您是未满14周岁的儿童或未满18周岁的未成年人，请您的父母或监护人仔细阅读本政策，并在得到您的父母或监护人同意后使用我们的服务或向我们提供信息。' },
            { fw: false, text: '2.对于经过父母或监护人同意而收集未成年个人信息的情况，我们只会在受到法律允许、父母或监护人明确同意或者保护未成年所必要的情况下使用或公开披露相关的信息。如您的监护人不同意您按照本政策使用我们的服务或向我们提供信息，请您立即终止使用我们的服务并及时通知我们，以便我们采取相应的措施。' },
            { fw: false, text: '3.为加强对未成年人个人信息的保护，除遵循本隐私政策项下的其他规定外，勤学将遵循正当必要、知情同意、目的明确、安全保障、依法利用的原则，根据国家相关法律法规及本隐私政策的规定保护未成年人的个人信息。' },
            { fw: true, text: '九、本隐私权政策的变更' },
            { fw: false, text: '本隐私权政策会依据实际情况或法律法规的变更而作相应修改或调整，如有调整，勤学会在相关页面上采取公告或弹窗的形式进行公示并重新征得您的同意。如您继续使用产品或服务，则视为您已接受修订后的服务条款。' },
            { fw: true, text: '十、如何联系我们或反馈意见' },
            { fw: false, text: '公司名称：合肥方了个田教育科技有限责任公司' },
            { fw: false, text: '注册地址：安徽省合肥市蜀山区龙图路与绿洲西路交口置地广场A座7层' },
            { fw: false, text: '如您对勤学的产品或服务有任何疑问或建议，您可以通过以下方式联系' },
            { fw: false, text: '我们：0551-69106630' }
        ]
    }
