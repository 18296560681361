
    import { Component, Vue, Prop } from 'vue-property-decorator'
    import { mapMutations } from 'vuex'
    @Component({
        name: 'RegistrationAgreement',
        methods: {
            ...mapMutations('common', ['setRegistrationAgreementFlag'])
        }
    })
    export default class RegistrationAgreement extends Vue {
        @Prop() title!: string
        setRegistrationAgreementFlag!: any
        private agreementsList: Array<any> = [
            { text: ['欢迎您注册成为勤学的用户！请仔细阅读下面的协议，只有接受协议才能继续进行注册。'], textIndent: false, fontWeight: false },
            { text: ['1．服务条款的确认和接纳'], textIndent: false, fontWeight: true },
            { text: ['（一）勤学用户服务的所有权和运作权归勤学拥有。勤学所提供的服务将按照有关章程、服务条款和操作规则严格执行。用户通过注册程序勾选“用户注册协议” 按钮，即表示用户与勤学达成协议并接受所有的服务条款。'], textIndent: true, fontWeight: false },
            { text: ['2. 用户同意：'], textIndent: false, fontWeight: true },
            {
                text: [
                    '（一）、提供及时、详尽及准确的个人资料。',
　　                '（二）、不断更新注册资料，符合及时、详尽、准确的要求。所有原始键入的资料将引用为注册资料。',
　　                '（三）、用户同意遵守《中华人民共和国保守国家秘密法》、《中华人民共和国计算机信息系统安全保护条例》、《计算机软件保护条例》等有关计算机及互联网规定的法律和法规、实施办法。在任何情况下，勤学合理地认为用户的行为可能违反上述法律、法规，勤学可以在任何时候，不经事先通知终止向该用户提供服务。用户应了解国际互联网的无国界性，应特别注意遵守当地所有有关的法律和法规。'
                ],
                textIndent: true,
                fontWeight: false
            },
            {
                text: [
                    '3．服务条款的修改'
                ],
                textIndent: false,
                fontWeight: true
            },
            {
                text: [
                    '（一）勤学会不定时地修改服务条款，服务条款一旦发生变动，将会在相关页面上提示修改内容。'
                ],
                textIndent: true,
                fontWeight: false
            },
            {
                text: [
                    '4．服务修订'
                ],
                textIndent: false,
                fontWeight: true
            },
            {
                text: [
                    '（一）勤学保留随时修改或中断服务而不需知照用户的权利。勤学行使修改或中断服务的权利，不需对用户或第三方负责。'
                ],
                textIndent: true,
                fontWeight: false
            },
            {
                text: [
                    '5．用户隐私制度'
                ],
                textIndent: false,
                fontWeight: true
            },
            {
                text: [
                    '尊重用户个人隐私是勤学的基本政策。勤学不会公开、编辑或透露用户的注册信息，除非有法律许可要求，或勤学在诚信的基础上认为透露这些信息在以下三种情况是必要的：'
                ],
                textIndent: false,
                fontWeight: false
            },
            {
                text: [
                    '（一）遵守有关法律规定，遵从合法服务程序。',
                　　'（二）保持维护勤学的商标所有权。',
                　　'（三）在紧急情况下竭力维护用户个人和社会大众的隐私安全。',
                　　'（四）符合其他相关的要求。'
                ],
                textIndent: true,
                fontWeight: false
            },
            {
                text: [
                    '6．用户的帐号，密码和安全性'
                ],
                textIndent: false,
                fontWeight: true
            },
            {
                text: [
                    '（一）一旦注册成功成为勤学用户，您将得到一个密码和帐号。如果您不保管好自己的帐号和密码安全，将对因此产生的后果负全部责任。另外，每个用户都要对其帐户中的所有活动和事件负全责。您可随时根据指示改变您的密码。'
                ],
                textIndent: true,
                fontWeight: false
            },
            {
                text: [
                    '7．免责条款'
                ],
                textIndent: false,
                fontWeight: true
            },
            {
                text: [
                    '（一）本网站对任何在本网站发布信息或使用信息所带来的风险不承担任何责任。访问和使用本网站者应自行判断相关信息的准确性、完整性和合法性，并同意自行承担访问和使用相关信息可能出现的全部风险。对您使用网站、与本网站相关的任何内容、服务或其它链接至本网站的站点、内容均不作直接、间接、法定、约定的保证。',
　　                '（二）无论在任何原因下（包括但不限于疏忽原因），对您或任何人通过使用本网站上的信息或由本网站链接的信息，或其他与本网站链接的网站信息所导致的损失或损害（包括直接、间接、特别或后果性的损失或损害，例如收入或利润之损失，电脑系统之损坏或数据丢失等后果），责任均由使用者自行承担（包括但不限于疏忽责任）。'
                ],
                textIndent: true,
                fontWeight: false
            },
            {
                text: [
                    '8．有限责任'
                ],
                textIndent: false,
                fontWeight: true
            },
            {
                text: [
                    '（一）勤学对任何直接、间接、偶然、特殊及继起的损害不负责任。'
                ],
                textIndent: true,
                fontWeight: false
            },
            {
                text: [
                    '9．用户责任'
                ],
                textIndent: false,
                fontWeight: true
            },
            {
                text: [
                    '用户单独承担传输内容的责任。用户必须遵循：'
                ],
                textIndent: false,
                fontWeight: false
            },
            {
                text: [
                    '（一）从中国境内向外传输技术性资料时必须符合中国有关法规。',
                　　'（二）使用网站服务不作非法用途。',
                　　'（三）不干扰或混乱网络服务。',
                　　'（四）不在论坛BBS或留言簿发表任何与政治相关的信息。',
                　　'（五）遵守所有使用网站服务的网络协议、规定、程序和惯例。',
                　　'（六）不得利用本站危害国家安全、泄露国家秘密，不得侵犯国家社会集体的和公民的合法权益。',
                　　'（七）不得利用本站制作、复制和传播下列信息：',
                　　'7.1煽动抗拒、破坏宪法和法律、行政法规实施的；',
                　　'7.2煽动颠覆国家政权，推翻社会主义制度的；',
                　　'7.3煽动分裂国家、破坏国家统一的；',
                　　'7.4煽动民族仇恨、民族歧视，破坏民族团结的；',
                　　'7.5捏造或者歪曲事实，散布谣言，扰乱社会秩序的；',
                　　'7.6宣扬封建迷信、淫秽、色情、赌博、暴力、凶杀、恐怖、教唆犯罪的；',
                　　'7.7公然侮辱他人或者捏造事实诽谤他人的，或者进行其他恶意攻击的；',
                　　'7.8损害国家机关信誉的；',
                　　'7.9其他违反宪法和法律行政法规的；',
                　　'7.10进行商业广告行为的。'
                ],
                textIndent: true,
                fontWeight: false
            },
            {
                text: [
                    '用户不能传输任何教唆他人构成犯罪行为的资料；不能传输涉及国家安全的资料；不能传输任何不符合当地法规、国家法律和国际法律的资料。未经许可而非法进入其它电脑系统是禁止的。若用户的行为不符合以上的条款，勤学将取消用户服务帐号。'
                ],
                textIndent: false,
                fontWeight: false
            },
            {
                text: [
                    '10．进行商业广告行为的'
                ],
                textIndent: false,
                fontWeight: true
            },
            {
                text: [
                    '（一）用户不能传输任何教唆他人构成犯罪行为的资料；不能传输涉及国家安全的资料；不能传输任何不符合当地法规、国家法律和国际法律的资料。未经许可而非法进入其它电脑系统是禁止的。若用户的行为不符合以上的条款，勤学将取消用户服务帐号。'
                ],
                textIndent: true,
                fontWeight: false
            },

            {
                text: [
                    '11．网站内容的所有权'
                ],
                textIndent: false,
                fontWeight: true
            },
            {
                text: [
                    '（一）勤学定义的内容包括：文字、软件、声音、相片、录象、图表；在广告中全部内容；电子邮件的全部内容；勤学为用户提供的商业信息。所有这些内容受版权、商标、标签和其它财产所有权法律的保护。所以，用户只能在勤学和广告商授权下才能使用这些内容，而不能擅自复制、篡改这些内容、或创造与内容有关的派生产品。勤学有权使用、宣传、保存课堂一切影像。'
                ],
                textIndent: true,
                fontWeight: false
            },

            {
                text: [
                    '12． 解释权'
                ],
                textIndent: false,
                fontWeight: true
            },
            {
                text: [
                    '（一）本注册协议的解释权归勤学所有。如果其中有任何条款与国家的有关法律相抵触，则以国家法律的明文规定为准。'
                ],
                textIndent: true,
                fontWeight: false
            },
        ]

        private closeEvent (): void {
            this.setRegistrationAgreementFlag(false)
        }
    }
